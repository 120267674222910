<template>
  <b-sidebar
    id="sidebar-invoice-cancel-payment"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    width="640px"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('Cancel Payment') }}          
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >
        <b-form-group
          :label="t('Invoice To')"
          label-for="invoice-to"
        >
          <div id="invoice-to-name" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">{{ invoiceData && invoiceData.customer ? invoiceData.customer.name : '' }}</div>
          <div id="invoice-to-taxid" style="font-size: 9.5pt; font-weight: 600; width: 100%;">{{ invoiceData && invoiceData.customer ? invoiceData.customer.taxid : '' }}</div>
        </b-form-group>

        <!--<b-form-group
          :label="`${invoiceData.type.v} (${invoiceData.type.vsat})`"
          label-for="invoice-folio"
        >
          <div id="invoice-folio" style="font-size: 9.5pt; font-weight: 600; width: 100%;">Folio: {{ invoiceData.index.serie }} {{ invoiceData.index.folio }}</div>
          <div id="invoice-folio-sat" style="font-size: 9.5pt; font-weight: 600; width: 100%;">Folio Fiscal: {{ invoiceData.index.folio_tax }}</div>
          <div id="invoice-signed-date" style="font-size: 9.5pt; font-weight: 600; width: 100%;">Fecha Emisión: {{ invoiceData.index.edate }}</div>          
        </b-form-group>-->
        <b-form-group
          label-for="invoice-folio"
        >
          <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0" style="width: 70%;">

              <!-- Header: Left Content -->
              <div>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Folio interno') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Folio fiscal') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Fecha de Emision') }}:
                </p>               
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Invoice Type')}}:
                </p>
              </div>

              <!-- Header: Right Content -->
              <div>
                  <p class="card-text mb-25 text-danger" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cancelPaymentData.cfdi && cancelPaymentData.cfdi.base_serie ? cancelPaymentData.cfdi.base_serie : '' }} {{ cancelPaymentData.cfdi && cancelPaymentData.cfdi.base_folio ? cancelPaymentData.cfdi.base_folio : '' }}</span>
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cancelPaymentData.cfdi && cancelPaymentData.cfdi.uuid ? cancelPaymentData.cfdi.uuid : '' }}</span>
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cancelPaymentData.control && cancelPaymentData.control.payment ? cancelPaymentData.control.payment.cfdi.cdate : ''}}</span>
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">Complemento de Pago (P)</span>
                  </p>                  
                </div>
              </div>
        </b-form-group><!--ILL: 200522 DETALLE DEL PAGO QUE SE VA A CANCELAR-->
          <div class="divider my-2">
            <div class="divider-text">
              {{ t('paymentdetail') }}              
            </div>
          </div>
        <b-form-group
          label-for="invoice-folio"
        >
          <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0" style="width: 70%;">

              <!-- Header: Left Content -->
              <div>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Fecha de pago') }}:
                </p>                
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Amount Paid') }}:
                </p>                
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Payment Form')}}:
                </p>
              </div>

              <!-- Header: Right Content -->
              <div>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cancelPaymentData && cancelPaymentData.cfdi ? cancelPaymentData.cfdi.base_cdate : '' }}</span>
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cancelPaymentData && cancelPaymentData.control ? cancelPaymentData.control.amount.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</span>
                  </p>                  
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cancelPaymentData && cancelPaymentData.control ? cancelPaymentData.control.payment.form.k : ''}} - {{ cancelPaymentData && cancelPaymentData.control ? cancelPaymentData.control.payment.form.v : ''}}</span>
                  </p>                  
                </div>
              </div>
        </b-form-group>        
        <!--ILL: 200522 SE REAJUSTA LA MANERA DE PRESENTAR LA INFORMACION
        <b-form-group
          :label="`Complemento de Pago a Cancelar (${cancelPaymentData.control.payment.number})`"
          label-for="cancel-folio"
        >
          <div id="cancel-folio" style="font-size: 9.5pt; font-weight: 600; width: 100%;">Folio: {{ cancelPaymentData.cfdi.base_serie }} {{ cancelPaymentData.cfdi.base_folio }}</div>
          <div id="cancel-folio-sat" style="font-size: 9.5pt; font-weight: 600; width: 100%;">Folio Fiscal: {{ cancelPaymentData.cfdi.uuid }}</div>
          <div id="cancel-folio-date" style="font-size: 9.5pt; font-weight: 600; width: 100%;">Fecha Pago: {{ cancelPaymentData.cfdi.base_cdate }}</div>
          <div id="cancel-amount" style="font-size: 9.5pt; font-weight: 600; width: 100%;">Monto: {{ cancelPaymentData.control.amount.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
          <div id="cancel-reason" style="font-size: 9.5pt; font-weight: 600; width: 100%;">Metodo de Pago: {{ cancelPaymentData.control.payment.form.k }} - {{ cancelPaymentData.control.payment.form.v }}</div>          
        </b-form-group>-->

        <b-form-group
          :label="t('Cancel Reason')"
          label-for="payment-cancel-reason"
        >
          <v-select
            v-model="cancelData.creason"
            :options="cancelReasonList"
            input-id="payment-cancel-reason"
            text-field="v"
            label="v"
            :clearable="false"
          >
            <template #selected-option="option">
              <div v-if="option !== null && option.v !== ''" style="display: flex; align-items: baseline">
                <strong>{{ option.k }}</strong>
                <em style="margin-left: 0.5rem">: {{ option.v && option.v.length > 64 ? option.v.substr(0,64) : option.v }}</em>
              </div>
              <div v-else>
              </div>
            </template>
            <template v-slot:option="option">
              {{ option.k }} : {{ option.v }}
            </template>
          </v-select>
        </b-form-group>

        <!--<b-form-row v-if="invoicePaymentData.creason && invoicePaymentData.creason.k === '01'">
          <b-col cols="4">
            <b-form-group
              label="Parciality Number"
              label-for="payment-number"
            >
              <b-form-input
                id="payment-number"
                v-model="invoicePaymentData.cfdi.pnumber"
                disabled
                trim
                size="sm"
                style="text-align: right;"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Payment Date"
              label-for="payment-date"
            >
              <b-form-datepicker
                id="payment-date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="es"
                size="sm"
                v-model="invoicePaymentData.pdate"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Payment Currency"
              label-for="payment-currency"
            >
              <b-form-input
                id="payment-currency"
                :value="`${invoiceData.payment.currency.k} - ${invoiceData.payment.currency.v}`"
                trim
                size="sm"
                disabled
                style="text-align: left;"
              />
            </b-form-group>
          </b-col>
        </b-form-row>-->
        <!-- Payment Balance, Amount, Remanent -->
        <!--<b-form-row v-if="cancelData.creason && cancelData.creason.k === '01'">	
          <b-col cols="4">
            <b-form-group
              label="Invoice Balance"
              label-for="invoice-balance"
            >
              <b-form-input
                id="invoice-balance"
                trim
                disabled
                size="sm"
                style="text-align: right;"
                :value="invoicePaymentData.cfdi.balance.before.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Payment Amount"
              label-for="payment-amount"
            >
              <b-form-input
                id="payment-amount"
                v-model="paymentAmount"
                trim
                size="sm"
                style="text-align: right;"
                @input="calculate"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Remanent Balance"
              label-for="remanent-balance"
            >
              <b-form-input
                id="remanent-balance"
                trim
                disabled
                size="sm"
                style="text-align: right;"
                :value="invoicePaymentData.cfdi.balance.after.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
              />
            </b-form-group>
          </b-col>
        </b-form-row>-->

        <!--<b-form-group v-if="cancelData.creason && cancelData.creason.k === '01'"
          label="Payment Method"
          label-for="payment-method"
        >
          <v-select
            v-model="cancelPaymentData.pform"
            :options="paymentFormList"
            input-id="payment-form"
            text-field="v"
            label="v"
            :clearable="false"
          >
            <template #selected-option="option">
              <div v-if="option !== {} && option.v !== ''" style="display: flex; align-items: baseline">
                <strong>{{ option.k }}</strong>
                <em style="margin-left: 0.5rem">: {{ option.v && option.v.length > 42 ? option.v.substr(0,42) : option.v }}</em>
              </div>
              <div v-else>
              </div>
            </template>
            <template v-slot:option="option">
              {{ option.k }} : {{ option.v }}
            </template>
          </v-select>
        </b-form-group>-->

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="onPaymentCancel"
          >
            {{ t('Emmit_Cfdi') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            {{ t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import paymentFormList from '@/@catalogs/sat/c_formapago'
import cancelReasonList from '@/@catalogs/sat/c_motivocancelacion'
import cfdiStoreModule from './cfdiStoreModule'
import store from '@/store'


export default {
  components: {
    BSidebar,
    BForm,
    BFormRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormDatepicker,

    vSelect,
  },
  props: {
    invoiceData: {
      type: Object|null,
      required: true,
    },
    cancelPaymentData: {
      type: Object|null,
      required: true,
    },
    invoiceEntities: {
      type: Array,
      required: true,
    }
  },
  directives: {
    Ripple,
  },
  watch: {
    __invoiceEntities(val) {
      this.invoicePaymentData.cfdi.uuid = this.invoiceData.index.folio_tax
      this.invoicePaymentData.cfdi.balance.before = this.invoiceData.summary.total
      this.invoicePaymentData.cfdi.pnumber = 1
      this.invoicePaymentData.currency = {k: this.invoiceData.payment.currency.k ,v: this.invoiceData.payment.currency.v}
      for(let i = 0; i < val.length; ++i) {
        if(val[i].lifecycle === 'cfdi.payment') {
          this.invoicePaymentData.cfdi.balance.before -= val[i].control.amount
          this.invoicePaymentData.cfdi.pnumber++
        }
      }

      /** normaliziing */
      this.invoicePaymentData.cfdi.balance.before = Number(this.invoicePaymentData.cfdi.balance.before.toFixed(2))
      this.invoicePaymentData.cfdi.balance.after  = this.invoicePaymentData.cfdi.balance.before
    }
  },
  data() {
    return {
      paymentFormList,
      cancelReasonList,
      paymentAmount: 0.0,
    }
  },
  methods: {
    calculate() {
      let amount = parseFloat(this.paymentAmount)
      this.invoicePaymentData.cfdi.balance.payed = amount
      this.invoicePaymentData.amount = amount
      this.invoicePaymentData.cfdi.balance.after = this.invoicePaymentData.cfdi.balance.before - amount

      if(this.invoicePaymentData.cfdi.balance.after === -0) this.invoicePaymentData.cfdi.balance.after = 0.0
    },
    onPaymentCancel() {
      // Hay que validar antes el envio
      console.log('cancelPaymentData', this.cancelPaymentData)
      console.log('cancelData', this.cancelData)
      this.$store.dispatch('fg-cfdi/cancelPaymentCfdi', { uuid: this.cancelPaymentData.cfdi.uuid, cancelData: this.cancelData })
        .then((response) => {
          /** Aqui incorporar la beautiful experience */
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('CFDi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('Payment folio ') + response.data.folio + ', cancel process started',
              },
            })
          
          localStorage.setItem('tokenBundle', JSON.stringify(response.data.tokenBundle))
          this.$root.$emit('tokens-refresh',{})

          this.$router.replace({ name: 'fg-cfdi-list' })
            .then(() => {
              
            })
        })
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'fg-cfdi'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, cfdiStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    /** lsandoval: 09/02/2022 We import the i18n utilities */
    const { t } = useI18nUtils()
    
    const cancelData = ref({
      creason: null
    })

    return {
      cancelData,
      
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
