export default [
    /*{
        k: '01',
        v: 'Comprobante emitido con errores con relación'
    },*/
    {
        k: '02',
        v: 'Comprobante emitido con errores sin relación'
    },
    {
        k: '03',
        v: 'No se llevó a cabo la operación'
    }
    /*,
    {
        k: '04',
        v: 'Operación nominativa relacionada en una factura global'
    } */       
]