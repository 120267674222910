export default [
{
    k: '16.00',
    v: '16.00',
    c_impuesto_k: '002'
},
{
    k: '10.6668',
    v: '10.6668',
    c_impuesto_k: '002'
},
{
    k: '10.6667',
    v: '10.6667',
    c_impuesto_k: '002'
},
{
    k: '10.66667',
    v: '10.66667',
    c_impuesto_k: '002'
},
{
    k: '10.667',
    v: '10.667',
    c_impuesto_k: '002'
},
{
    k: '10.67',
    v: '10.67',
    c_impuesto_k: '002'
},
{
    k: '10.666665',
    v: '10.666665',
    c_impuesto_k: '002'
},
{
    k: '10.6666',
    v: '10.6666',
    c_impuesto_k: '002'
},
{
    k: '10.66',
    v: '10.66',
    c_impuesto_k: '002'
},
{
    k: '10.60',
    v: '10.60',
    c_impuesto_k: '002'
},
{
    k: '10.00',
    v: '10.00',
    c_impuesto_k: '002'
},
{
    k: '9.19',
    v: '9.19',
    c_impuesto_k: '002'
},
{
    k: '8.00',
    v: '8.00',
    c_impuesto_k: '002'
},
{
    k: '6.00',
    v: '6.00',
    c_impuesto_k: '002'
},
{
    k: '5.40',
    v: '5.40',
    c_impuesto_k: '002'
},
{
    k: '5.3333',
    v: '5.3333',
    c_impuesto_k: '002'
},
{
    k: '5.00',
    v: '5.00',
    c_impuesto_k: '002'
},
{
    k: '4.00',
    v: '4.00',
    c_impuesto_k: '002'
},
{
    k: '3.00',
    v: '3.00',
    c_impuesto_k: '002'
},
{
    k: '2.20',
    v: '2.20',
    c_impuesto_k: '002'
},
{
    k: '2.00',
    v: '2.00',
    c_impuesto_k: '002'
},
{
    k: '0.70',
    v: '0.70',
    c_impuesto_k: '002'
},
{
    k: '0.50',
    v: '0.50',
    c_impuesto_k: '002'
},
{
    k: '0.20',
    v: '0.20',
    c_impuesto_k: '002'
},
{
    k: '0',
    v: '0',
    c_impuesto_k: '002'
}]
