<template>
  <b-sidebar
    id="invoice-sidebar-share"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    ref="isshare"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('COMPARTIR') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- From -->
        <!--<b-form-group
          label="From"
          label-for="from"
        >
          <b-form-input
            id="from"
            v-model="sendInvoiceData.from"
            trim
            type="email"
          />
        </b-form-group>-->

        <!-- To -->
        <b-form-group
          :label="t('Para')"
          label-for="to"
        >
          <b-form-input
            id="to"
            v-model="invoiceShareData.to"
            trim
            type="email"
            size="sm"
          />
        </b-form-group>

        <!-- Subject -->
        <b-form-group
          :label="t('Subject')"
          label-for="subject"
        >
          <b-form-input
            id="subject"
            v-model="invoiceShareData.subject"
            trim
            size="sm"
          />
        </b-form-group>

        <!-- ? File/Attachement -->
        <b-form-group
          :label="t('Adjuntos')"
          label-for="attach"
        >
          <div style="width: 100%">
            <b-badge variant="light-info" style="width: 40%">
              <feather-icon icon="FileTextIcon" />
              <span class="ml-50">{{ t('CFDI (xml)') }}</span>
            </b-badge>
          </div>

          <div style="width: 100%">
            <b-badge variant="light-info" style="width: 40%">
              <feather-icon icon="FileIcon" />
              <span class="ml-50">{{ t('CFDI (pdf)') }}</span>
            </b-badge>
          </div>
        </b-form-group>
        

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="!sendEnabled"
            @click="onShare"
          >
            {{ t('Send') }}            
          </b-button>
          <!--<b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>-->
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    invoiceShareData: {
      type: Object,
      required: true,
      
    }
  },
  computed: {
    sendEnabled: {
      get() {
        return this.invoiceShareData.to && this.invoiceShareData.to !== '' && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.invoiceShareData.to);
      }
    }
  },
  methods: {
    onShare() {
      console.group('onShare')
      console.log('share', this.invoiceShareData)
      // Hay que validar antes el envio
      this.$store.dispatch('fg-cfdi/shareCfdi', this.invoiceShareData )
        .then((response) => {
          /** Aqui incorporar la beautiful experience */
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('CFDi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('CFDi shared with') + response.data.target,
              },
            })

          console.log('response',response)
          console.groupEnd()

          this.$refs.isshare.hide();
        })
    }
  },
  setup() {
    /** ILL: 230522 We import the i18n utilities */
    const { t } = useI18nUtils()
    
    return {    
      t,
    }
  }  
}
</script>

<style>

</style>
