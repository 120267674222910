<template>
  <b-sidebar
    id="sidebar-invoice-add-payment"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    no-close-on-esc
    no-close-on-backdrop
    shadow
    backdrop
    no-header
    right
    width="640px"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('PAYMENT') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >
        <div class="divider my-0" >
            <div class="divider-text" >
              {{ t('originalinvoice') }}              
            </div>
        </div>        
        <b-form-group
          :label="t('Invoice To')"
          label-for="invoice-to"
        >
          <div id="invoice-to-name" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">{{ invoiceData && invoiceData.customer ? invoiceData.customer.name : '' }}</div>
          <div id="invoice-to-taxid" style="font-size: 9.5pt; font-weight: 600; width: 100%;">{{ invoiceData && invoiceData.customer ? invoiceData.customer.taxid : '' }}</div>
        </b-form-group>

        <b-form-group          
          label-for="invoice-folio"
        >
          <b-form-row>
            <b-col cols="10">
              <b-form-row>
                <!-- Header: Left Content -->
                <b-col cols="4">
                  <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                    {{ t('Folio interno') }}:
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                    {{ t('Folio fiscal') }}:
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                    {{ t('Fecha de Emision') }}:
                  </p>
                  <!--ILL: 220422 SE AGREGA EL MONTO DE LA FACTURA ORIGINAL QUE DEBERÁ DESENCADENAR LOS PAGOS-->
                  <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                    {{ t('Monto Original') }}:
                  </p>                
                  <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                    {{ t('Invoice Type')}}:
                  </p>
                </b-col>

                <!-- Header: Right Content -->
                <b-col cols="8">
                  <p class="card-text mb-25 text-danger" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ invoiceData && invoiceData.index ? invoiceData.index.serie : '' }} {{ invoiceData && invoiceData.index ? invoiceData.index.folio : '' }}</span>
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ invoiceData && invoiceData.index ? invoiceData.index.folio_tax : ''}}</span>
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ invoiceData && invoiceData.index ? invoiceData.index.edate : ''}}</span>
                  </p>
                  <!--ILL: 220422 SE AGREGA EL MONTO DE LA FACTURA ORIGINAL QUE DEBERÁ DESENCADENAR LOS PAGOS-->
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <!--<span class="invoice-number">{{ invoiceData && invoiceData.summary ? invoiceData.summary.total.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</span>-->
                    <span class="invoice-number">{{ invoiceData && invoiceData.summary ? invoiceData.summary.total.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}} {{ invoiceData && invoiceData.payment && invoiceData.payment.currency.k !== 'MXN' ? '(' + invoiceData.payment.currency.k + ')' : '' }}</span>
                  </p>                  
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ invoiceData && invoiceData.type ? invoiceData.type.v : ''}} ({{ invoiceData && invoiceData.type ? invoiceData.type.vsat : ''}})</span>
                  </p>                  
                </b-col>
              </b-form-row>

            </b-col>
            <b-col cols="2">
              <div v-if="!isCEPData" class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                <b-tooltip
                  :title="t('Upload CEP tooltip')"
                  class="cursor-pointer"
                  :target="`files-upload-id`"
                />
                <form  enctype="multipart/form-data" novalidate @dragover="handleOnDragOver" @dragleave="onDragLeave" @drop="onDrop">
                  <div class="container-input">
                    <div class="dropbox" ref="uploading-box">
                      <input type="file" multiple :name="uploadFieldName"  @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                        accept="application/pdf" class="input-file" id="files-upload-id" title="">
                        <b-icon 
                        id="cfdi-advisor"
                        icon="file-earmark-arrow-up"
                        scale="3"
                        class="rounded-circle"
                        color="#fff"
                      />
                    </div>
                    <span>{{t('Upload CEP')}}</span>
                  </div>
                </form>  
              </div>
              <div v-if="isCEPData" class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                <div class="container-input">
                  <div class="dropbox" ref="uploading-box">
                    <b-icon 
                      icon="x-circle"
                      scale="3"
                      class="rounded-circle"
                      color="#fff"
                      @click="clearCEP"
                    />
                  </div>
                  <span>{{t('Clear CEP')}}</span>
                </div>
              </div>
            </b-col>
          </b-form-row>
        </b-form-group>
        <div class="divider my-2">
          <div class="divider-text">
            {{ t('paymentdetail') }}              
          </div>
        </div>
        <b-form-row v-if="isCEPData">
          <b-col cols="10">
            <b-form-row>
              <b-col cols="4">
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Parciality Number') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Fecha de pago') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('payment_currency') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Balance') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Amount Covered') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Remanent') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Payment Form') }}:
                </p>
              </b-col>
              <b-col cols="8">
                <p class="card-text mb-25 text-danger" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.cfdi.pnumber }}</span>
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.pdatetime }}</span>
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.pCurrency && invoicePaymentData.pCurrency.k ? `${invoicePaymentData.pCurrency.k} - ${invoicePaymentData.pCurrency.v}` : '' }}</span>
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.cfdi.balance.before.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                </p>                  
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.cfdi.balance.payed.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                </p>         
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.cfdi.balance.after.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.pform && invoicePaymentData.pform.v ? `${invoicePaymentData.pform.k} - ${invoicePaymentData.pform.v}` : '' }}</span>
                </p>         
              </b-col>
            </b-form-row>
          </b-col>
          <b-col cols="2">
          </b-col>
        </b-form-row>
        <div v-if="isCEPData" class="divider my-2">
          <div class="divider-text">
            {{ t('Transaction Detail') }}              
          </div>
        </div>
        <b-form-row v-if="isCEPData">
          <b-col cols="10">
            <b-form-row>
              <b-col cols="4">
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_source_name') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_source_taxid') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_source_bank') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_source_account') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_target_account') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_transaction_id') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_transaction_amount') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_transaction_currency') }}:
                </p>
              </b-col>
              <b-col cols="8">
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.customer.fullname ? invoicePaymentData.collection.customer.fullname : '' }}</span>
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.customer.taxid ? invoicePaymentData.collection.customer.taxid : '' }}</span>
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.customer.bank_name ? invoicePaymentData.collection.customer.bank_name : '' }}</span>
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.customer.account ? invoicePaymentData.collection.customer.account : '' }}</span>
                </p>                  
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.transaction.account ? invoicePaymentData.collection.transaction.account : '' }}</span>
                </p>         
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.transaction.tracking_key ? invoicePaymentData.collection.transaction.tracking_key : '' }}</span>
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.transaction.amount ? invoicePaymentData.collection.transaction.amount.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00' }}</span>
                </p>         
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.transaction.currency ? invoicePaymentData.collection.transaction.currency : '' }}</span>
                </p>         
              </b-col>
            </b-form-row>
          </b-col>
          <b-col cols="2">
          </b-col>
        </b-form-row>
        <b-form-row v-if="isCEPData">
          <b-col cols="10">
            <b-form-row>
              <b-col cols="4">
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_s_certnumber') }}:
                </p>
                <!--<p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_s_source') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('p_s_seal') }}:
                </p>-->
              </b-col>
              <b-col cols="8">
                <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.signature.cert_number ? invoicePaymentData.collection.signature.cert_number : '' }}</span>
                </p>
                <!--<p class="card-text mb-25" style="font-size: 8pt; font-weight: 400; width: 100%; text-justify: inter-character;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.signature.source ? invoicePaymentData.collection.signature.source : '' }}</span>
                </p>
                <p class="card-text mb-25" style="font-size: 8pt; font-weight: 400; width: 100%; text-justify: inter-character;">
                  <span class="invoice-number">{{ invoicePaymentData.collection && invoicePaymentData.collection.signature.seal ? invoicePaymentData.collection.signature.seal : '' }}</span>
                </p>-->
              </b-col>
            </b-form-row>
          </b-col>
          <b-col cols="2">
          </b-col>
        </b-form-row>
        
        <b-form-row v-if="!isCEPData">
          <b-col cols="4">
            <b-form-group
              :label="t('Parciality Number')"              
              label-for="payment-number"
            >
              <b-form-input
                id="payment-number"
                v-model="invoicePaymentData.cfdi.pnumber"
                disabled
                trim
                size="sm"
                style="text-align: right;"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="t('Fecha de pago')"
              label-for="payment-date"
            >
              <b-form-datepicker
                id="payment-date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="es"
                size="sm"
                placeholder=""
                v-model="invoicePaymentData.pdate"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="t('payment_currency')"
              label-for="payment-currency"
            >
              <b-form-select
                id="payment-currency"
                v-model="invoicePaymentData.pCurrency"
                text-field="k"
                label-field="k"
                size="sm"
                @input="calculate"
              >
                <option v-for="option in currencyList" :key="option.k" :value="option">
                  {{ option.k }}
                </option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="!isCEPData">
          <b-col cols="4">
            <b-form-group
              :label="`${t('Amount Paid')} ${invoicePaymentData && invoiceData && invoicePaymentData.pCurrency && invoiceData.payment && invoiceData.payment.currency && (invoicePaymentData.pCurrency.k !==  invoiceData.payment.currency.k) ? '(' + invoicePaymentData.pCurrency.k + ')' : ''}`"
              label-for="payment-amount"
            >
              <b-form-input
                id="payment-amount"
                v-model="invoicePaymentData.amount"
                trim
                size="sm"
                type="number"
                style="text-align: right;"
                @input="calculate"
              />
             
            </b-form-group>
          </b-col>
          <b-col cols="4" v-if="invoicePaymentData && invoiceData && invoicePaymentData.pCurrency && invoiceData.payment && (invoicePaymentData.pCurrency.k !== 'MXN' || invoiceData.payment.currency.k !== 'MXN')">
            <b-form-group
              :label="`MXN/${invoicePaymentData && invoicePaymentData.pCurrency && invoicePaymentData.pCurrency.k !== 'MXN' ? invoicePaymentData.pCurrency.k : invoiceData.payment.currency.k} ${t('exchange')}` "
              label-for="payment-mxnexchange"
            >
              <b-form-input
                id="payment-mxnexchange"
                v-model="paymentCurrencyExchange"
                size="sm"
                type="number"
                style="text-align: right;"
                @input="calculate"
              />
            </b-form-group>
          </b-col>
          <!--<b-col cols="4" v-if="invoicePaymentData.pCurrency && invoicePaymentData.pCurrency.k !== invoiceData.payment.currency.k">
            <b-form-group
              :label="`${invoicePaymentData.pCurrency.k}/${invoiceData.payment.currency.k} ${t('exchange')}`"
              label-for="payment-currencyexchange"
            >
              <b-form-input
                id="payment-currencyexchange"
                v-model="paymentCurrencyInvoiceExchange"
                size="sm"
                type="number"
                style="text-align: right;"
                @input="calculate"
              />
            </b-form-group>
          </b-col>-->
        </b-form-row>
        <!-- Payment Balance, Amount, Remanent -->
        <b-form-row v-if="!isCEPData">	
          <b-col cols="4">
            <b-form-group
              :label="`${t('Balance')} ${ invoiceData && invoiceData.payment && invoiceData.payment.currency.k !== 'MXN' ? '(' + invoiceData.payment.currency.k + ')' : ''}`"
              label-for="invoice-balance"
            >
              <b-form-input
                id="invoice-balance"
                trim
                disabled
                size="sm"
                style="text-align: right;"
                :value="invoicePaymentData.cfdi.balance.before.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="`${t('Amount Covered')} ${invoiceData && invoiceData.payment && invoiceData.payment.currency.k !== 'MXN' ? '(' + invoiceData.payment.currency.k + ')' : ''}`"
              label-for="payment-amount"
            >
              <b-form-input
                id="payment-amount"
                :value="invoicePaymentData.cfdi.balance.payed.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                trim
                disabled
                size="sm"
                style="text-align: right;"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="`${t('Remanent')} ${invoiceData && invoiceData.payment && invoiceData.payment.currency.k !== 'MXN' ? '(' + invoiceData.payment.currency.k + ')' : ''}`"
              label-for="remanent-balance"
            >
              <b-form-input
                id="remanent-balance"
                trim
                disabled
                size="sm"
                style="text-align: right;"
                :value="invoicePaymentData.cfdi.balance.after.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
              />
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-group v-if="!isCEPData"
          :label="t('Payment Form')"
          label-for="payment-method"
        >
          <v-select
            v-model="invoicePaymentData.pform"
            :options="paymentFormList"
            input-id="payment-form"
            text-field="k"
            label="k"
            size="sm"
            :clearable="false"
          >
            <template #selected-option="option">
              <div v-if="option && option.v !== ''" style="display: flex; align-items: baseline">
                <strong>{{ option.k }}</strong>
                <em style="margin-left: 0.5rem">: {{ option.v && option.v.length > 42 ? option.v.substr(0,42) : option.v }}</em>
              </div>
              <div v-else>
              </div>
            </template>
            <template v-slot:option="option">
              {{ option.k }} : {{ option.v }}
            </template>
          </v-select>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-overlay
            :show="onEmmitBusy"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="!isEmmitReady"
              @click="onPaymentEmmit"
            >
              {{ t('Emmit_Cfdi') }}
            </b-button>
          </b-overlay>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BOverlay, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BButton, BFormRow, BCol, BFormDatepicker, BTooltip
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { DateTime } from "luxon"
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import paymentFormList from '@/@catalogs/sat/c_formapago'
import cfdiStoreModule from './cfdiStoreModule'
/** fer@arkeline.dev 22/03/2023 Product Currency Feature on CFDi Add */
import currencyList from '@/@catalogs/sat/c_moneda'
import store from '@/store'


export default {
  components: {
    BSidebar,
    BForm,
    BFormRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BTooltip,

    vSelect,
  },
  props: {
    invoiceData: {
      type: Object|null,
      required: true,
    },
    invoiceEntities: {
      type: Array,
      required: true,
    }
  },
  directives: {
    Ripple,
  },
  watch: {
    invoiceEntities(val) {
      this.invoicePaymentData.cfdi.uuid = this.invoiceData.index.folio_tax
      this.invoicePaymentData.cfdi.balance.before = this.invoiceData.summary.total

      this.invoicePaymentData.cfdi.pnumber = 1
      //this.invoicePaymentData.currency = {k: this.invoiceData.payment.currency.k ,v: this.invoiceData.payment.currency.v}
      for(let i = 0; i < val.length; ++i) {
        if(val[i].lifecycle === 'cfdi.payment') {
          this.invoicePaymentData.cfdi.balance.before -= ( val[i].control.payment.currency ? val[i].control.balance.payed : val[i].control.amount )
          this.invoicePaymentData.cfdi.pnumber++
        }
      }

      /** normaliziing */
      this.invoicePaymentData.cfdi.balance.before = Number(this.invoicePaymentData.cfdi.balance.before.toFixed(2))
      this.invoicePaymentData.cfdi.balance.after  = this.invoicePaymentData.cfdi.balance.before
    }
  },
  data() {
    return {
      paymentFormList,
      paymentAmount: 0.0,
      paymentCurrencyExchange: '1.0',
      paymentCurrencyInvoiceExchange: '',
      paymentCurrencyMXNExchange: '',
      /** fer@arkeline.io 22/03/2023 Product Currency Feature for CFDi Add */
      currencyList,

      onEmmitBusy: false,
      /** fer@arkeline.io 18/07/2024 CEP Uploading Feature */
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      isUploading: false,
      uploadingError: false,
      isCEPData: false,
    }
  },
  computed: {
    isEmmitReady: {
      get () {
        if(!this.isCEPData) {
          return this.invoicePaymentData.cfdi.uuid !== '' 
                && this.invoicePaymentData.cfdi.pnumber !== ''
                && this.invoicePaymentData.pdate !== '' 
                && this.invoicePaymentData.amount !== 0.0
                && this.invoicePaymentData.cfdi.balance.after >= 0.0
                && this.invoicePaymentData.pform && this.invoicePaymentData.pform.k !== ''
        } else {
          return this.invoicePaymentData.cfdi.uuid !== '' 
                && this.invoicePaymentData.cfdi.pnumber !== ''
                && this.invoicePaymentData.pdatetime !== '' 
                && this.invoicePaymentData.amount !== 0.0
                && this.invoicePaymentData.cfdi.balance.after >= 0.0
                && this.invoicePaymentData.pform && this.invoicePaymentData.pform.k !== ''
        }
      }
    }
  },
  methods: {
    async filesChange(fieldName, fileList) {
        this.uploadingError = false;
        this.isUploading = true;
        function _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        }
        const arrayBuffer = await fileList[0].arrayBuffer();
        const base64 = _arrayBufferToBase64(arrayBuffer)
        try {
          const response = await store.dispatch('fg-cfdi/getCEP', {
            banxico: {
              cep: base64
            }
        })
        const data   = response?.data?.data;
        const status = response?.data?.response;

          if(status === "success") {
            this.invoicePaymentData.pdatetime = DateTime.fromISO(data?.fecha_abono).setZone('America/Mexico_City').toFormat("yyyy-MM-dd'T'HH:mm:ss")
            this.invoicePaymentData.amount = data?.pago?.monto || 0.0
            this.invoicePaymentData.cfdi.balance.payed = data?.pago?.monto || 0.0
            this.invoicePaymentData.pform = { k: '03', v: 'Transferencia electrónica de fondos', or: 2, a: 's', b: 'o', c: 'o', d: 'o', e: '[0-9]{10}|[0-9]{16}|[0-9]{18}', f: 'o', g: 'o', h: '[0-9]{10}|[0-9]{18}', i: 'o', j: ['XEXX010101000'] }
            this.invoicePaymentData.pCurrency = { k: 'MXN', v: 'Peso Mexicano', or: 1, de: 2 }
            this.invoicePaymentData.pCurrencyMXNExchange = 1.0
            this.invoicePaymentData.pCurrencyInvoiceExchange = 1.0

            const _collection = {
              partner: 'partner.cep.loader',
              uuid: data?.pago?.claveRastreo || '',
              transaction: {
                tracking_key: data?.pago?.claveRastreo || '',
                amount: data?.pago?.monto || 0.0,
                currency: 'MXN',
                account: data?.beneficiario?.cuenta || ''
              },
              signature: {
                cert_number: data?.firma_digital?.serie_certificado || '',
                source: data?.firma_digital?.cadena_original || '',
                seal: data?.firma_digital?.sello_digital || ''
              },
              customer: {
                fullname: data?.ordenante?.titular?.nombre || '',
                taxid: data?.ordenante?.titular?.rfc || data?.ordenante?.titular?.curp || '',
                bank_name: data?.ordenante?.institucion || '',
                account: data?.ordenante?.cuenta || ''
              }
            }
            this.invoicePaymentData.collection = _collection
            this.calculate()
            this.isCEPData = true;
          } else { 
            this.uploadingError = true;
          }
        } catch (error) {
          this.uploadingError = true;
        }finally{
          this.isUploading = false;
        }
    },
    handleOnDragOver(e){
      this.$refs["uploading-box"].classList.add("dropbox-over")
    },
    onDragLeave(){
      this.$refs["uploading-box"].classList.remove("dropbox-over")
    },
    onDrop(){
      this.$refs["uploading-box"].classList.remove("dropbox-over")
    },
    removeErrorCIF(){
      this.uploadingError = false;
    },
    clearCEP() {
      delete this.invoicePaymentData.collection
      delete this.invoicePaymentData.pdatetime

      this.invoicePaymentData.amount = 0.0
      this.invoicePaymentData.cfdi.balance.payed =  0.0
      this.invoicePaymentData.pform = null
      this.invoicePaymentData.pCurrency = { k: 'MXN', v: 'Peso Mexicano', or: 1, de: 2 }
      this.invoicePaymentData.pCurrencyMXNExchange = 1.0
      this.invoicePaymentData.pCurrencyInvoiceExchange = 1.0
      this.isCEPData = false
    },
    calculate() {
      let amount          = this.invoicePaymentData.amount !== '' && !isNaN(parseFloat(this.invoicePaymentData.amount)) ? parseFloat(this.invoicePaymentData.amount) :  0.0
      let paymentExchange = this.paymentCurrencyExchange !== '' && !isNaN(parseFloat(this.paymentCurrencyExchange)) ? parseFloat(this.paymentCurrencyExchange) :  1.0
      console.info('paymentExchange', paymentExchange)
      
      if(this.invoiceData.payment.currency.k === 'MXN' && this.invoicePaymentData.pCurrency.k === 'MXN') {
        /** Caso A Factura en MXN, Pago en MXN pCurrencyMXNExchange = 1, pCurrencyInvoiceExchange = 1 */
        this.paymentCurrencyMXNExchange = 1.0
        this.paymentCurrencyInvoiceExchange = 1.0
        this.invoicePaymentData.pCurrencyMXNExchange = this.paymentCurrencyMXNExchange
        this.invoicePaymentData.pCurrencyInvoiceExchange = this.paymentCurrencyInvoiceExchange

        this.invoicePaymentData.cfdi.balance.payed = amount
        console.info('MXNMXN')
      } else if(this.invoiceData.payment.currency.k === 'MXN' && this.invoicePaymentData.pCurrency.k !== 'MXN') {
        /** Caso B Factura en MXN, Pago en USD pCurrencyMXNExchange = paymentCurrencyExchange , pCurrencyInvoiceExchange = 1 */
        this.paymentCurrencyMXNExchange = paymentExchange
        this.paymentCurrencyInvoiceExchange = paymentExchange
        this.invoicePaymentData.pCurrencyMXNExchange = this.paymentCurrencyMXNExchange
        this.invoicePaymentData.pCurrencyInvoiceExchange = this.paymentCurrencyInvoiceExchange

        this.invoicePaymentData.cfdi.balance.payed = parseFloat(parseFloat(amount * this.invoicePaymentData.pCurrencyInvoiceExchange).toFixed(6))
        console.info('MXN!MXN')
      } else if(this.invoiceData.payment.currency.k !== 'MXN' && this.invoicePaymentData.pCurrency.k === 'MXN') {
        /** Caso C Factura en USD, Pago en MXN paymentCurrencyMXNExchange = 1.0, paymentCurrencyInvoiceExchange = 1 / paymentCurrencyExchange */
        this.paymentCurrencyMXNExchange = 1.0
        this.paymentCurrencyInvoiceExchange = parseFloat((1.0 / paymentExchange).toFixed(6))
        this.invoicePaymentData.pCurrencyMXNExchange = this.paymentCurrencyMXNExchange
        this.invoicePaymentData.pCurrencyInvoiceExchange = this.paymentCurrencyInvoiceExchange

        this.invoicePaymentData.cfdi.balance.payed = parseFloat(parseFloat(amount * this.invoicePaymentData.pCurrencyInvoiceExchange).toFixed(6))
        console.info('!MXNMXN')
      } else if(this.invoiceData.payment.currency.k !== 'MXN' && (this.invoiceData.payment.currency.k === this.invoiceData.payment.currency.k)) {
        /** Caso D Factura en USD, Pago en USD paymentCurrencyMXNExchange = paymentExchange, paymentCurrencyInvoiceExchange = 1 */
        this.paymentCurrencyMXNExchange = paymentExchange
        this.paymentCurrencyInvoiceExchange = 1.0 
        this.invoicePaymentData.pCurrencyMXNExchange = this.paymentCurrencyMXNExchange
        this.invoicePaymentData.pCurrencyInvoiceExchange = this.paymentCurrencyInvoiceExchange

        this.invoicePaymentData.cfdi.balance.payed = amount
        console.info('!MXN!MXN')
      }

      /** fer@arkeline.io 28/03/2023 If the currency of the payment is different of the currency of Invoice, we need to perform calculation using pCurrencyInvoiceExchange
       *                             exchange (paymentCurrency / invoiceCurrency)
       */
      /**if(this.invoicePaymentData.pCurrency.k !== this.invoiceData.payment.currency.k) {
        if(this.invoicePaymentData.pCurrency.k === 'MXN') {
          this.paymentCurrencyMXNExchange = '1.00'
          this.invoicePaymentData.pCurrencyMXNExchange = 1.0
        }
        this.invoicePaymentData.cfdi.balance.payed = parseFloat(amount * this.invoicePaymentData.pCurrencyInvoiceExchange)
      } else {
        if(this.invoicePaymentData.pCurrency.k !== 'MXN') {
          this.paymentCurrencyInvoiceExchange = '1.00'
          this.invoicePaymentData.pCurrencyInvoiceExchange = 1.0
        }
        this.invoicePaymentData.cfdi.balance.payed = parseFloat(amount * this.invoicePaymentData.pCurrencyInvoiceExchange)
      }**/
      
      this.invoicePaymentData.amount = amount
      
      this.invoicePaymentData.cfdi.balance.after = this.invoicePaymentData.cfdi.balance.before - this.invoicePaymentData.cfdi.balance.payed >= 0.0 ? Math.abs(this.invoicePaymentData.cfdi.balance.before - this.invoicePaymentData.cfdi.balance.payed) : this.invoicePaymentData.cfdi.balance.before - this.invoicePaymentData.cfdi.balance.payed
      if(this.invoicePaymentData.cfdi.balance.after === -0) this.invoicePaymentData.cfdi.balance.after = 0.0

    },
    onPaymentEmmit() {

      this.onEmmitBusy = true
      // Hay que validar antes el envio
      this.$store.dispatch('fg-cfdi/emmitPaymentCfdi', { uuid: this.invoiceData.uuid, payment: this.invoicePaymentData })
        .then((response) => {

          this.onEmmitBusy = false
          /** Aqui incorporar la beautiful experience */
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('CFDi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('Payment Emmited with folio ') + response.data.folio,
              },
            })

          localStorage.setItem('tokenBundle', JSON.stringify(response.data.tokenBundle))
          this.$root.$emit('tokens-refresh',{})

          this.$router.replace({ name: 'fg-cfdi-list' })
            .then(() => {
              
            })
        })
        .catch( (error) => {
            /** Nacho's here we need the beautifull experience */
            this.onEmmitBusy = false
            /** Aqui incorporar la beautiful experience */
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('CFDi'),
                icon: 'AlertTriangleIcon',
                variant: 'warning',
                //text: error.error.message,
                text: error.error.message.startsWith('CFDI') ? error.error.message : this.$t(error.error.message),
              },
            })
        })
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'fg-cfdi'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, cfdiStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    /** lsandoval: 09/02/2022 We import the i18n utilities */
    const { t } = useI18nUtils()
    
    const invoicePaymentData = ref({
      pdate: '',
      pform: null,
      pCurrency: { k: 'MXN', v: 'Peso Mexicano', or: 1, de: 2 },
      pCurrencyMXNExchange: 1.0,
      pCurrencyInvoiceExchange: 1.0,
      amount: 0.0,
      cfdi: {
        uuid: '',
        pnumber: '',
        balance: {
          before: 0.0,
          payed: 0.0,
          after: 0.0,
        }
      }
    })

    return {
      invoicePaymentData,
      
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
