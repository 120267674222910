export default [
    {
        k: '20.00',
        v: '20.00',
        c_impuesto_k: '001'
    },
    {
        k: '10.666',
        v: '10.666',
        c_impuesto_k: '001'
    },
    {
        k: '10.00',
        v: '10.00',
        c_impuesto_k: '001'
    },
    {
        k: '5.40',
        v: '5.40',
        c_impuesto_k: '001'
    },
    {
        k: '4.00',
        v: '4.00',
        c_impuesto_k: '001'
    },
    {
        k: '3.00',
        v: '3.00',
        c_impuesto_k: '001'
    },
    {
        k: '2.10',
        v: '2.10',
        c_impuesto_k: '001'
    },
    {
        k: '2.00',
        v: '2.00',
        c_impuesto_k: '001'
    },
    {
        k: '1.25',
        v: '1.25',
        c_impuesto_k: '001'
    },
    {
        k: '1.10',
        v: '1.10',
        c_impuesto_k: '001'
    },
    {
        k: '1.00',
        v: '1.00',
        c_impuesto_k: '001'
    },
    {
        k: '0.90',
        v: '0.90',
        c_impuesto_k: '001'
    },
    {
        k: '0.50',
        v: '0.50',
        c_impuesto_k: '001'
    },
    {
        k: '0.40',
        v: '0.40',
        c_impuesto_k: '001'
    },
    {
        k: '0.10',
        v: '0.10',
        c_impuesto_k: '001'
    },
    {
        k: '0',
        v: '0',
        c_impuesto_k: '001'
    }
]