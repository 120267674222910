<template>
  <b-sidebar
    id="sidebar-invoice-cancel"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    width="640px"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('CANCEL') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >
        <b-form-group
          :label="t('Invoice To')"
          label-for="invoice-to"
        >
          <div id="invoice-to-name" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">{{ invoiceData && invoiceData.customer ? invoiceData.customer.name : '' }}</div>
          <div id="invoice-to-taxid" style="font-size: 9.5pt; font-weight: 600; width: 100%;">{{ invoiceData && invoiceData.customer ? invoiceData.customer.taxid : '' }}</div>
        </b-form-group>

        <b-form-group
          label-for="invoice-folio"
        >
          <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0" style="width: 70%;">

              <!-- Header: Left Content -->
              <div>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Folio interno') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Folio fiscal') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Fecha de Emision') }}:
                </p>
                <!--ILL: 200522 SE AGREGA EL MONTO DE LA FACTURA ORIGINAL QUE DEBERÁ DESENCADENAR LOS PAGOS-->
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Monto Original') }}:
                </p>                
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Invoice Type')}}:
                </p>
              </div>

              <!-- Header: Right Content -->
              <div>
                  <p class="card-text mb-25 text-danger" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ invoiceData && invoiceData.index ? invoiceData.index.serie : ''}} {{ invoiceData && invoiceData.index ? invoiceData.index.folio : ''}}</span>
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ invoiceData && invoiceData.index ? invoiceData.index.folio_tax : ''}}</span>
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ invoiceData && invoiceData.index ? invoiceData.index.edate : ''}}</span>
                  </p>
                  <!--ILL: 200522 SE AGREGA EL MONTO DE LA FACTURA ORIGINAL QUE DEBERÁ DESENCADENAR LOS PAGOS-->
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ invoiceData && invoiceData.summary ? invoiceData.summary.total.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</span>
                  </p>                  
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ invoiceData && invoiceData.type ? invoiceData.type.v : ''}} ({{ invoiceData && invoiceData.type ? invoiceData.type.vsat : ''}})</span>
                  </p>                  
                </div>
              </div>
        </b-form-group>

        <b-form-group
          :label="t('Cancel Reason')"
          label-for="payment-cancel-reason"
        >
          <v-select
            v-model="invoiceCancelData.creason"
            :options="cancelReasonList"
            input-id="payment-cancel-reason"
            text-field="v"
            label="v"
            :clearable="false"
          >
            <template #selected-option="option">
              <div v-if="option !== {} && option.v !== ''" style="display: flex; align-items: baseline">
                <strong>{{ option.k }}</strong>
                <em style="margin-left: 0.5rem">: {{ option.v && option.v.length > 42 ? option.v.substr(0,42) : option.v }}</em>
              </div>
              <div v-else>
              </div>
            </template>
            <template v-slot:option="option">
              {{ option.k }} : {{ option.v }}
            </template>
          </v-select>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-overlay
            :show="onCancelBusy"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="onInvoiceCancel"
            >
              {{ t('Start_Cfdi_Cancel') }}
            </b-button>
          </b-overlay>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BOverlay, BButton, BFormRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import paymentFormList from '@/@catalogs/sat/c_formapago'
import cancelReasonList from '@/@catalogs/sat/c_motivocancelacion'
import cfdiStoreModule from './cfdiStoreModule'
import store from '@/store'


export default {
  components: {
    BSidebar,
    BForm,
    BFormRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay,
    BButton,
    BFormDatepicker,

    vSelect,
  },
  props: {
    invoiceData: {
      type: Object|null,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  watch: {
    
  },
  data() {
    return {
      paymentFormList,
      cancelReasonList,
      paymentAmount: 0.0,
      onCancelBusy: false,
    }
  },
  methods: {
    calculate() {
      let amount = parseFloat(this.paymentAmount)
      this.invoicePaymentData.cfdi.balance.payed = amount
      this.invoicePaymentData.amount = amount
      this.invoicePaymentData.cfdi.balance.after = this.invoicePaymentData.cfdi.balance.before - amount

      if(this.invoicePaymentData.cfdi.balance.after === -0) this.invoicePaymentData.cfdi.balance.after = 0.0
    },
    onInvoiceCancel() {
      console.group('onCancel')
      console.log('uuid', this.invoiceData.index.folio_tax)
      this.onCancelBusy = true
      // Hay que validar antes el envio
      this.$store.dispatch('fg-cfdi/cancelEmmitedCfdi', { uuid: this.invoiceData.index.folio_tax, cancelData: this.invoiceCancelData })
        .then((response) => {
          this.onCancelBusy = false
          /** Aqui incorporar la beautiful experience */
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('CFDi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('Invoice folio ') + response.data.folio + ', cancel process started',
              },
            })

          localStorage.setItem('tokenBundle', JSON.stringify(response.data.tokenBundle))
          this.$root.$emit('tokens-refresh',{})

          this.$router.replace({ name: 'fg-cfdi-list' })
            .then(() => {
              
            })

          console.log('response',response)
          console.groupEnd()
        })
        .catch((error) => {
          console.log('error',error)
          console.groupEnd()

          this.onCancelBusy = false
          /** Aqui incorporar la beautiful experience */
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('CFDi'),
                icon: 'AlertTriangleIcon',
                variant: 'warning',
                text: this.$t('Can\'t Cancel') + ' [' + this.invoiceData.index.folio_tax + '], ' + this.$t('due to: ') + error.error.message,
              },
            })
        })
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'fg-cfdi'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, cfdiStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    /** lsandoval: 09/02/2022 We import the i18n utilities */
    const { t } = useI18nUtils()
    
    const invoiceCancelData = ref({
      creason: null,
    })

    return {
      invoiceCancelData,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
