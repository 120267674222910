<template>
  <b-sidebar
    id="cfdi-view-add-credit-note"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    no-close-on-esc
    no-close-on-backdrop
    shadow
    backdrop
    no-header
    right
    width="640px"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ t('Credit Note') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >
        <div class="divider my-0" >
            <div class="divider-text" >
              {{ t('originalinvoice') }}              
            </div>
        </div>       
        <b-form-group
          :label="t('Invoice To')"
          label-for="invoice-to"
        >
          <div id="invoice-to-name" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">{{ cfdiData && cfdiData.customer ? cfdiData.customer.name : '' }}</div>
          <div id="invoice-to-taxid" style="font-size: 9.5pt; font-weight: 600; width: 100%;">{{ cfdiData && cfdiData.customer ? cfdiData.customer.taxid : '' }}</div>
        </b-form-group>

        <b-form-group          
          label-for="invoice-folio"
        >
          <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0" style="width: 70%;">

              <!-- Header: Left Content -->
              <div>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Folio interno') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Folio fiscal') }}:
                </p>
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Fecha de Emision') }}:
                </p>
                <!--ILL: 220422 SE AGREGA EL MONTO DE LA FACTURA ORIGINAL QUE DEBERÁ DESENCADENAR LOS PAGOS-->
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Monto Original') }}:
                </p>                
                <p class="card-text mb-25" style="font-size: 9.5pt; text-overflow: ellipsis; width: 100%;">
                  {{ t('Invoice Type')}}:
                </p>
              </div>

              <!-- Header: Right Content -->
              <div>
                  <p class="card-text mb-25 text-danger" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cfdiData && cfdiData.index ? cfdiData.index.serie : '' }} {{ cfdiData && cfdiData.index ? cfdiData.index.folio : '' }}</span>
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cfdiData && cfdiData.index ? cfdiData.index.folio_tax : ''}}</span>
                  </p>
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cfdiData && cfdiData.index ? cfdiData.index.edate : ''}}</span>
                  </p>
                  <!--ILL: 220422 SE AGREGA EL MONTO DE LA FACTURA ORIGINAL QUE DEBERÁ DESENCADENAR LOS PAGOS-->
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cfdiData && cfdiData.summary ? cfdiData.summary.total.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}</span>
                  </p>                  
                  <p class="card-text mb-25" style="font-size: 9.5pt; font-weight: 600; width: 100%;">
                    <span class="invoice-number">{{ cfdiData && cfdiData.type ? cfdiData.type.v : ''}} ({{ cfdiData && cfdiData.type ? cfdiData.type.vsat : ''}})</span>
                  </p>                  
                </div>
              </div>
        </b-form-group>
        <div class="divider my-2">
          <div class="divider-text">
            {{ t('credit note detail') }}              
          </div>
        </div>
        <b-form-row>
          <b-col cols="9">
            <b-form-group
              :label="t('Description')"              
              label-for="note-desc-0"
            >
              <b-form-input
                id="note-desc-0"
                v-model="cfdiCreditNoteData.items[0].name"
                trim
                size="sm"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
              <b-form-group
                :label="t('Amount')"
                label-for="note-amount-0"
                :invalid-feedback="t('desamount')"
                v-b-tooltip.hover.top="t('tprice')"
                label-size="sm"
              >
                <b-form-input
                  id="note-amount-0"
                  trim
                  size="sm"
                  style="text-align: right;"
                  v-model="cfdiCreditNoteData.items[0].price"
                  :state="amountValidation"
                  @input="calculate(0)"
                />
              </b-form-group>
          </b-col>
          <!--<b-col cols="4">
            <b-form-group
              :label="t('Fecha de pago')"
              label-for="payment-date"
            >
              <b-form-datepicker
                id="payment-date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="es"
                size="sm"
                v-model="cfdiCreditNoteData.pdate"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="t('Moneda')"
              label-for="payment-currency"
            >
              <b-form-input
                id="payment-currency"
                :value="`${cfdiData && cfdiData.payment ? cfdiData.payment.currency.k : ''} - ${cfdiData && cfdiData.payment ? cfdiData.payment.currency.v : ''}`"
                trim
                size="sm"
                disabled
                style="text-align: left;"
              />
            </b-form-group>
          </b-col>-->
        </b-form-row>
        <!-- Payment Balance, Amount, Remanent -->
        
        <b-form-row>
          <b-col cols="6">
            </b-col>
          <b-col cols="3">
              <b-form-group
                :label="t('Apply IVA Translate')"
                label-for="tiva_translate_apply"
                label-size="sm"
              >
                <b-form-checkbox
                  id="tiva_translate_apply"
                  v-model="productData.taxes.iva_ta"
                  switch
                  @change="productData.taxes.iva_ta === true ? calculate(0) : productData.taxes.iva_tr = ''; calculate(0)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                :label="t('IVA Translate')"
                label-for="tiva_translate"
                label-size="sm"
              >
              <b-form-select
                id="tiva_translate"
                :disabled="productData.taxes.iva_ta === false ? true : false"
                v-model="productData.taxes.iva_tr"
                text-field="v"
                label-field="v"
                size="sm"
                @change="calculate(0)"
              >
                <option v-for="option in sattaxIvaTranslateList" :key="option.k" :value="option">
                  {{ option.v }}
                </option>
              </b-form-select>
              </b-form-group>
            </b-col>
        </b-form-row>


        <!-- IVA ret -->

        <b-form-row>	
          <b-col cols="6">
            </b-col>
          <b-col cols="3">
              <b-form-group
                :label="t('Apply IVA Retention')"
                label-for="tiva_retention_apply"
                label-size="sm"
              >
                <b-form-checkbox
                  id="tiva_retention_apply"
                  v-model="productData.taxes.iva_ra"
                  switch
                  @change="productData.taxes.iva_ra === true ? calculate(0) : productData.taxes.iva_rr = ''; calculate(0)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                :label="t('IVA Retention')"
                label-for="tiva_retention"
                label-size="sm"
              >
              <b-form-select
                id="tiva_retention"
                :disabled="productData.taxes.iva_ra === false ? true : false"
                v-model="productData.taxes.iva_rr"
                text-field="v"
                label-field="v"
                size="sm"
                @change="calculate(0)"
              >
                <option v-for="option in sattaxIvaRetentionList" :key="option.k" :value="option">
                  {{ option.v }}
                </option>
              </b-form-select>
              </b-form-group>
            </b-col>
        </b-form-row>

        <!-- ISR ret -->

        <b-form-row>
          <b-col cols="6">
            </b-col>
          <b-col cols="3">
              <b-form-group
                :label="t('Apply ISR Retention')"
                label-for="tisr_retention_apply"
                label-size="sm"
              >
                <b-form-checkbox
                  id="tisr_retention_apply"
                  v-model="productData.taxes.isr_ra"
                  switch
                  @change="productData.taxes.isr_ra === true ? calculate(0) : productData.taxes.isr_rr = ''; calculate(0)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                :label="t('ISR Retention')"
                label-for="tisr_retention"
                label-size="sm"
              >
              <b-form-select
                id="tisr_retention"
                :disabled="productData.taxes.isr_ra === false ? true : false"
                v-model="productData.taxes.isr_rr"
                text-field="v"
                label-field="v"
                size="sm"
                @change="calculate(0)"
              >
                <option v-for="option in sattaxIsrRetentionList" :key="option.k" :value="option">
                  {{ option.v }}
                </option>
              </b-form-select>
              </b-form-group>
            </b-col>
        </b-form-row>

        <!-- divider -->
        <div class="divider my-2">
          <div class="divider-text">
            {{ t('Total') }}              
          </div>
        </div>
        <b-form-row>
          <b-col cols="2" style="margin-left: auto;">
            <b-form-group
              :label="t('Subtotal')"
              label-for="invoice-subtotal"
              style="margin-bottom: 0.25rem; width: 100%;"
            >
              <b-form-input
                id="invoice-subtotal"
                disabled
                size="sm"
                style="text-align: right;"
                :value="cfdiCreditNoteData.summary.subtotal.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2" v-if="cfdiCreditNoteData.summary.discount > 0.0">
            <b-form-group
              :label="t('Discount')"
              label-for="invoice-discount"
              style="margin-bottom: 0.25rem; width: 100%;"
            >
              <b-form-input
                id="invoice-discount"
                disabled
                size="sm"
                style="text-align: right;"
                :value="cfdiCreditNoteData.summary.discount.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2" v-if="cfdiCreditNoteData.taxes.total_iva_t > 0.0">
            <b-form-group
                :label="t('IVA Translate')"
                label-for="invoice-total-iva-t"
                style="margin-bottom: 0.25rem; width: 100%;"
              >
                <b-form-input
                  id="invoice-total-iva-t"
                  disabled
                  size="sm"
                  style="text-align: right;"
                  :value="cfdiCreditNoteData.taxes.total_iva_t.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                />
              </b-form-group>
          </b-col>
          <b-col cols="2" v-if="cfdiCreditNoteData.taxes.total_iva_r > 0.0">
            <b-form-group
                :label="t('IVA Retention')"
                label-for="invoice-total-iva-r"
                style="margin-bottom: 0.25rem; width: 100%;"
              >
                <b-form-input
                  id="invoice-total-iva-r"
                  disabled
                  size="sm"
                  style="text-align: right;"
                  :value="cfdiCreditNoteData.taxes.total_iva_r.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                />
              </b-form-group>
          </b-col>
          <b-col cols="2" v-if="cfdiCreditNoteData.taxes.total_isr_r > 0.0">
            <b-form-group
                :label="t('ISR Retention')"
                label-for="invoice-total-isr-r"
                style="margin-bottom: 0.25rem; width: 100%;"
              >
                <b-form-input
                  id="invoice-total-isr-r"
                  disabled
                  size="sm"
                  style="text-align: right;"
                  :value="cfdiCreditNoteData.taxes.total_isr_r.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
                />
              </b-form-group>
          </b-col>
          <b-col cols=2>
            <b-form-group
              :label="t('Total')"
              label-for="invoice-total"
              style="margin-bottom: 0.25rem; width: 100%;"
            >
              <b-form-input
                id="invoice-total"
                disabled
                size="sm"
                style="text-align: right;"
                :value="cfdiCreditNoteData.summary.total.toLocaleString('es-MX',{style:'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})"
              />
            </b-form-group>
          </b-col>
        </b-form-row>

        <div class="divider my-2">
          <div class="divider-text">
            {{ t('Payment Form') }}              
          </div>
        </div>

        <b-form-group
          :label="t('Payment Form')"
          label-for="payment-method"
        >
          <v-select
            v-model="cfdiCreditNoteData.payment.form"
            :options="paymentFormList"
            input-id="payment-form"
            text-field="v"
            label="v"
            :clearable="false"
          >
            <template #selected-option="option">
              <div v-if="option !== {} && option.v !== ''" style="display: flex; align-items: baseline">
                <strong>{{ option.k }}</strong>
                <em style="margin-left: 0.5rem">: {{ option.v && option.v.length > 42 ? option.v.substr(0,42) : option.v }}</em>
              </div>
              <div v-else>
              </div>
            </template>
            <template v-slot:option="option">
              {{ option.k }} : {{ option.v }}
            </template>
          </v-select>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-overlay
            :show="onEmmitBusy"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="!isEmmitReady"
              @click="onCreditEmmit"
            >
              {{ t('Emmit_Cfdi') }}
            </b-button>
          </b-overlay>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BOverlay, BFormGroup, BFormInput, BFormTextarea, BButton, BFormRow, BCol, BFormCheckbox, VBTooltip, BFormSelect, BFormDatepicker,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import paymentFormList from '@/@catalogs/sat/c_formapago'
import cfdiStoreModule from './cfdiStoreModule'
import sattaxIvaTranslateList from '@/@catalogs/sat/c_iva_tra'
import sattaxIvaRetentionList from '@/@catalogs/sat/c_iva_ret'
import sattaxIsrRetentionList from '@/@catalogs/sat/c_isr_ret'
import store from '@/store'


export default {
  components: {
    BSidebar,
    BForm,
    BFormRow,
    BCol,
    BFormCheckbox,
    BFormSelect,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormDatepicker,

    vSelect,
  },
  props: {
    cfdiData: {
      type: Object|null,
      required: true,
    },
    cfdiEntities: {
      type: Array,
      required: true,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  watch: {
    invoiceEntities(val) {
      /**this.cfdiCreditNoteData.cfdi.uuid = this.cfdiData.index.folio_tax
      this.cfdiCreditNoteData.cfdi.balance.before = this.cfdiData.summary.total
      this.cfdiCreditNoteData.cfdi.pnumber = 1
      this.cfdiCreditNoteData.currency = {k: this.cfdiData.payment.currency.k ,v: this.cfdiData.payment.currency.v}
      for(let i = 0; i < val.length; ++i) {
        if(val[i].lifecycle === 'cfdi.payment') {
          this.cfdiCreditNoteData.cfdi.balance.before -= val[i].control.amount
          this.cfdiCreditNoteData.cfdi.pnumber++
        }
      }**/

      /** normaliziing */
      /**this.cfdiCreditNoteData.cfdi.balance.before = Number(this.cfdiCreditNoteData.cfdi.balance.before.toFixed(2))
      this.cfdiCreditNoteData.cfdi.balance.after  = this.cfdiCreditNoteData.cfdi.balance.before**/
    }
  },
  data() {
    return {
      amountValidation: true,
      paymentFormList,
      paymentAmount: 0.0,
      onEmmitBusy: false,

      sattaxIvaTranslateList,
      sattaxIvaRetentionList,
      sattaxIsrRetentionList,
      productData: {
        taxes : {
          iva_ta: false,
          iva_ra: false,
          isr_ra: false,
          ieps_ta: false,
          ieps_ra: false,
          iva_tr: '',
          iva_rr: '',
          isr_rr: '',
          ieps_tt: 'rate',
          ieps_tr: '',
          ieps_tq: '',
          ieps_rt: 'rate',
          ieps_rr: '',
          ieps_rq: ''
        }
      }
    }
  },
  computed: {
    isEmmitReady: {
      get () {
        return this.cfdiCreditNoteData.items[0].name !== '' 
                && this.cfdiCreditNoteData.items[0].price
                && this.cfdiCreditNoteData.payment.form.k
                && (this.productData.taxes.iva_ta && this.productData.taxes.iva_tr.k ||
                  !this.productData.taxes.iva_ta)
                && (this.productData.taxes.iva_ra && this.productData.taxes.iva_rr.k ||
                  !this.productData.taxes.iva_ra)
                && (this.productData.taxes.isr_ra && this.productData.taxes.isr_rr.k ||
                  !this.productData.taxes.isr_ra)
                && this.amountValidation;
      }
    }
  },
  methods: {
    calculate(idx) {
      console.log('CfdiViewAddCreditNote->calculate',idx)
      this.cfdiSaved = false

      if(idx !== -1) {
        let item = this.cfdiCreditNoteData.items[idx]
        const regEx = /^[0-9]+(\.[0-9]{2})?$/
        this.amountValidation = regEx.test(item.price)
        if(!this.amountValidation) return;

        if(this.productData.taxes.iva_ta === true) {
          if(this.productData.taxes.iva_tr.k === 'Exento'){
            item.taxes['iva_t'] = {t:'exempt', r: 'Exento', a: 0.0 }
          } else {
            item.taxes['iva_t'] = {t:'rate', r: this.productData.taxes.iva_tr.k, a: 0.0 }
          }
          item.meta.tax_object = '02'
        }else{
          item.taxes['iva_t'] = undefined
        }

        if(this.productData.taxes.iva_ra === true) {
          item.taxes['iva_r'] = {t:'rate', r: this.productData.taxes.iva_rr.k, a: 0.0 }
          item.meta.tax_object = '02'
        }else{
          item.taxes['iva_r'] = undefined
        }

        if(this.productData.taxes.isr_ra === true) {
          item.taxes['isr_r'] = {t:'rate', r: this.productData.taxes.isr_rr.k, a: 0.0 }
          item.meta.tax_object = '02'
        }else{
          item.taxes['isr_r'] = undefined
        }

      /** subtotal = ((price + (price * ieps_r / 100)) - discount ) * qty  */
        item.price = parseFloat(item.price || '0') || 0.0
        item.price_tt = parseFloat(parseFloat(item.price).toFixed(2))
        /**if(item.taxes.ieps_t) {
          if(item.taxes.ieps_t.t === 'rate') {
            item.price_t['ieps'] = item.price * (parseFloat(item.taxes.ieps_t.r) / 100.0)
            item.price_tt += item.price_t['ieps']
          } else {
            item.price_t['ieps'] = parseFloat(item.taxes.ieps_t.q)
            item.price_tt += item.price_t['ieps']
          }
        }**/

        if(item.discount.value.indexOf('%') !== -1) {
          item.discount.amount = parseFloat((parseFloat(item.price_tt * parseFloat(item.qty !== '' ? item.qty : 0.00)) * (parseFloat(item.discount.value.substr(0,item.discount.value.indexOf('%'))) / 100.00)).toFixed(2))
        } else {
          item.discount.amount = parseFloat(parseFloat(item.discount.value !== '' ? item.discount.value : 0.00).toFixed(2))
        }

        item.subtotal = parseFloat(item.price_tt * parseFloat(item.qty !== '' ? item.qty : 0.00))
        item.subtotal_tt = parseFloat((item.price_tt * parseFloat(item.qty !== '' ? item.qty : 0.00)) - item.discount.amount)
        item.total = item.subtotal_tt

        item.taxes.total_t = 0.0
        item.taxes.total_r = 0.0
        
        if(item.taxes.iva_t) {
          if(item.taxes.iva_t.r === 'Exento'){
            item.taxes.iva_t.a = 0.0
          } else {
            item.taxes.iva_t.a = item.subtotal_tt * (parseFloat(item.taxes.iva_t.r ? item.taxes.iva_t.r : 0.0) / 100)
          }
          item.taxes.total_t += parseFloat(parseFloat(item.taxes.iva_t.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
          item.total += parseFloat(parseFloat(item.taxes.iva_t.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
        }  
        
        
        /**if(item.taxes.ieps_t) {
          item.taxes.ieps_t.a = parseFloat(item.qty !== '' ? item.qty : '0') * item.price_t['ieps']
        }  **/ 

        if(item.taxes.iva_r) {
          console.log(item.taxes.iva_r);
          item.taxes.iva_r.a = item.subtotal_tt * (parseFloat(item.taxes.iva_r.r || 0.0) / 100)
          item.taxes.total_r += parseFloat(parseFloat(item.taxes.iva_r.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
          item.total -= parseFloat(parseFloat(item.taxes.iva_r.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
        }

        if(item.taxes.isr_r) {
          item.taxes.isr_r.a = item.subtotal_tt * (parseFloat(item.taxes.isr_r.r || 0.0) / 100)
          item.taxes.total_r += parseFloat(parseFloat(item.taxes.isr_r.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
          item.total -= parseFloat(parseFloat(item.taxes.isr_r.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
        }
      }

      this.cfdiCreditNoteData.taxes.total_iva_t = 0;
      this.cfdiCreditNoteData.taxes.total_ieps_t = 0;
      this.cfdiCreditNoteData.taxes.total_iva_r = 0;
      this.cfdiCreditNoteData.taxes.total_isr_r = 0;
      this.cfdiCreditNoteData.summary.total_t = 0;
      this.cfdiCreditNoteData.summary.total_r = 0;
      this.cfdiCreditNoteData.summary.subtotal = 0;
      this.cfdiCreditNoteData.summary.discount = 0;
      this.cfdiCreditNoteData.summary.total = 0;
      
      for(let it of this.cfdiCreditNoteData.items) {
        if(it.taxes.iva_t) {
          this.cfdiCreditNoteData.taxes.total_raw_iva_t += it.taxes.iva_t.a
          this.cfdiCreditNoteData.taxes.total_iva_t += parseFloat(parseFloat(it.taxes.iva_t.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
          this.cfdiCreditNoteData.summary.total_t += parseFloat(parseFloat(it.taxes.iva_t.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
        }

        if(it.taxes.ieps_t) {
          this.cfdiCreditNoteData.taxes.total_raw_ieps_t += it.taxes.ieps_t.a
          this.cfdiCreditNoteData.taxes.total_ieps_t += parseFloat(parseFloat(it.taxes.ieps_t.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
          this.cfdiCreditNoteData.summary.total_t += parseFloat(parseFloat(it.taxes.ieps_t.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
        }

        if(it.taxes.iva_r) {
          this.cfdiCreditNoteData.taxes.total_raw_iva_r += it.taxes.iva_r.a
          this.cfdiCreditNoteData.taxes.total_iva_r += parseFloat(parseFloat(it.taxes.iva_r.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
          this.cfdiCreditNoteData.summary.total_r += parseFloat(parseFloat(it.taxes.iva_r.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
        }

        if(it.taxes.isr_r) {
          this.cfdiCreditNoteData.taxes.total_raw_isr_r += it.taxes.isr_r.a
          this.cfdiCreditNoteData.taxes.total_isr_r += parseFloat(parseFloat(it.taxes.isr_r.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
          this.cfdiCreditNoteData.summary.total_r += parseFloat(parseFloat(it.taxes.isr_r.a).toFixed(this.cfdiCreditNoteData.payment.currency.de))
        }

        this.cfdiCreditNoteData.summary.subtotal += it.subtotal
        this.cfdiCreditNoteData.summary.discount += it.discount.amount
        this.cfdiCreditNoteData.summary.total += it.total
      }
    },
    onCreditEmmit() {
      this.onEmmitBusy = true
      // Hay que validar antes el envio
      this.$store.dispatch('fg-cfdi/emmitCreditCfdi', { uuid: this.cfdiData.uuid, credit: this.cfdiCreditNoteData })
        .then((response) => {
          console.log("response ",response);
          this.onEmmitBusy = false
          /** Aqui incorporar la beautiful experience */
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('CFDi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: this.$t('Credit Note Emmited with folio ') + response.data.folio,
              },
            })

          localStorage.setItem('tokenBundle', JSON.stringify(response.data.tokenBundle))
          this.$root.$emit('tokens-refresh',{})

          this.$router.replace({ name: 'fg-cfdi-list' })
            .then(() => {
              
            })
        })
        .catch( (error) => {
          console.log("error ",error);
            /** Nacho's here we need the beautifull experience */
        })
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'fg-cfdi'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, cfdiStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    /** lsandoval: 09/02/2022 We import the i18n utilities */
    const { t } = useI18nUtils()
    
    const cfdiCreditNoteData = ref({
      pdate: '',
      items: [
        {
          uuid: '',
          id: 'G-NC-IT',
          satid: '84111506',
          satu: 'ACT',
          satu_str: 'Actividad',
          sku: '',
          qty: 1,
          type: '',
          name: '',
          config: '*',
          extra: {},
          fixed: false,
          price: 0.0,
          price_t : {

          },
          price_tt: 0.0,
          discount: {
            value: '',
            amount: 0.0,
          },
          subtotal: 0.0,
          subtotal_tt: 0.0,
          total: 0.0,
          taxes: {
            total_r: 0.0,
            total_t: 0.0
          },
          meta: {
            tax_object: '01'
          }
        }
      ],
      summary: {
        subtotal: 0.0,
        discount: 0.0,
        total_t: 0.0,
        total_r: 0.0,
        total: 0.0
      },
      taxes: {
        total_raw_iva_t: 0.0,
        total_raw_ieps_t: 0.0,
        total_raw_iva_r: 0.0,
        total_raw_isr_r: 0.0,
        total_iva_t: 0.0,
        total_ieps_t: 0.0,
        total_iva_r: 0.0,
        total_isr_r: 0.0
      },
      addendum: {},
      observations: '',
      payment: {
        method: {
          k: 'PUE',
          v: 'Pago en una sola exhibición'
        },
        form: {},
        currency: { k: 'MXN', v: 'Peso Mexicano', or: 1, de: 2 }
      }
    })

    return {
      cfdiCreditNoteData,
      
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
