export default [
{
    k: '16.00',
    v: '16.00',
    c_impuesto_k: '002'
},
{
    k: '8.00',
    v: '8.00',
    c_impuesto_k: '002'
},
{
    k: '0',
    v: '0',
    c_impuesto_k: '002'
},
{
    k: 'Exento',
    v: 'Exento',
    c_impuesto_k: '002'
}
]